import CircularLoader from '@capturi/assets/images/CircularLoader.svg'
import { Flex, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { format } from 'date-fns/fp'
import React from 'react'
import KeyTopicTitle from './styles/KeyTopicTitle'
import MagicBox from './styles/MagicBox'
import MagicBoxHeader from './styles/MagicBoxHeader'

type Props = {
  subTopic: string | null
  topic: string | null
  summary: string | null
  isLoading: boolean
  error: Error | null
  date: Date
}

const formatTime = format('hh:MM')

const VoiceBot: React.FC<Props> = ({
  error,
  isLoading,
  subTopic,
  summary,
  topic,
  date,
}) => {
  if (isLoading)
    return (
      <Flex justifyContent="center">
        <CircularLoader width="90px" height="90px" />
      </Flex>
    )
  if (error) return <Text>{error.message}</Text>

  return (
    <MagicBox>
      <MagicBoxHeader flexDir="row" justifyContent="space-between">
        <KeyTopicTitle keyTopic={topic} subKeyTopic={subTopic} />
        <Text>{formatTime(date)}</Text>
      </MagicBoxHeader>
      <Text whiteSpace="break-spaces" p="3">
        {summary || t`No details available`}
      </Text>
    </MagicBox>
  )
}

export default VoiceBot
