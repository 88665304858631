import { UseQueryResult, useQuery } from '@tanstack/react-query'
import ky from 'ky'
import qs from 'query-string'

import { API_PREFIX } from '../../config'
import { RequestError, extractHttpError } from './extractHttpError'
import { log } from './log'

//documentation can be found here:
// https://api.capturi.ai/puzzel-widget/documentation/swagger

export type VoiceBotPayload = {
  phoneNumber?: string
}

export type VoiceBotResponse = {
  status: 'OK' | 'Processing' | 'Error'
  date: string
  summary: string | null
  topic: string | null
  subTopic: string | null
}

export const useVoiceBot = (
  apiToken: string,
  payload: VoiceBotPayload,
): UseQueryResult<VoiceBotResponse | undefined, RequestError> =>
  useQuery({
    enabled: !!(apiToken && payload.phoneNumber),
    queryKey: [apiToken, 'voice bot summary', payload.phoneNumber],
    retryDelay: 0,
    queryFn: async () => {
      try {
        return await ky
          .get(
            qs.stringifyUrl({
              url: `${API_PREFIX}/puzzel-widget/voice-bot-details`,
              query: {
                date: new Date().toJSON(),
                'phone-number': payload.phoneNumber,
              },
            }),
            {
              credentials: 'include',
              mode: 'cors',
              headers: {
                Authorization: apiToken,
              },
            },
          )
          .json<VoiceBotResponse | undefined>()
      } catch (error) {
        const e = await extractHttpError(error)
        log(apiToken, {
          data: { name: e.name, message: e.message, ...e.cause },
          level: 'error',
          message: 'useConversations error',
        })

        throw e
      }
    },
  })
