import { formatSeconds } from '@capturi/ui-components'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import SummaryBox from 'components/SummaryBox'
import KeyTopicTitle from 'components/styles/KeyTopicTitle'
import MagicBox from 'components/styles/MagicBox'
import MagicBoxHeader from 'components/styles/MagicBoxHeader'
import React from 'react'

type Props = {
  agent: {
    email: string
    name: string
  }
  team?: string
  subject: string
  duration: number
  dateTime: string
  customSummary: string
  conversationUid: string
  keyTopic?: string
  subKeyTopic?: string
  rootCause?: string
}

const formatDate = new Intl.DateTimeFormat('da-DK', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}).format
const SingleConversation: React.FC<Props> = ({
  agent,
  dateTime,
  duration,
  subject,
  customSummary,
  keyTopic,
  subKeyTopic,
  rootCause,
}) => {
  const formattedDuration = formatSeconds(duration)
  return (
    <MagicBox>
      <MagicBoxHeader>
        <Flex w="100%" justify="space-between" gap="4" alignItems="center">
          <KeyTopicTitle keyTopic={keyTopic} subKeyTopic={subKeyTopic} />
          <Flex
            flex="0 2 500px"
            justifyContent="flex-end"
            gap="1"
            alignItems="baseline"
            minW="0"
          >
            <Text
              fontSize="xs"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              minW="0"
            >
              {subject}
            </Text>
            <Text fontSize="xs">|</Text>
            <Text
              fontSize="sm"
              fontWeight={500}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {agent.name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          justify="space-between"
          textAlign="right"
          alignItems="center"
        >
          <Text color="gray.600" fontSize="sm">
            <Trans>{formattedDuration} minutes</Trans>
          </Text>
          <Text color="gray.600" fontSize="sm">
            {formatDate(new Date(dateTime))}
          </Text>
        </Flex>
      </MagicBoxHeader>
      <SummaryBox rootCause={rootCause} customSummary={customSummary} />
    </MagicBox>
  )
}

export default SingleConversation
