import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { connect } from '@puzzel/widget-api-lib'
import { Config, getConfig } from 'components/api/useConfig'
import { usePostHog } from 'posthog-js/react'
import React, { useEffect, useRef, useState } from 'react'
import Widget from './Widget'
import ErrorComponent from './components/ErrorComponent'
import NotConnected from './components/NotConnected'
import { extractHttpError } from './components/api/extractHttpError'
import { log } from './components/api/log'
import { extractLocale } from './components/extractLocale'

type SystemCall = {
  system_call_progress:
    | 'CONNECTED'
    | 'HANGUP'
    | 'ALERTING'
    | 'NOANSWER'
    | 'ABORTED'
    | 'SPLIT'
    | 'BUSY'
  system_caller_ano: string
  language: string
  callerStatus: 'New Call'
  current_state: string
  system_caller_transfered: boolean
  __CLIENT_IS_OUTBOUND__: boolean
  ciq_destination: string
  system_session_id: string
}

type PuzzelCall = {
  sessionId: string
  caller: string
}

const PuzzelLayer: React.FC = () => {
  const { i18n } = useLingui()
  const posthog = usePostHog()
  const [customer, setCustomer] = useState<string | null>(null)
  const [previousCustomer, setPreviousCustomer] = useState<string>('')
  const [apiToken, setApiToken] = useState<string | null>(null)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const configRef = useRef<Config | null>(null)

  const defaultWaitForConnectionBeforeShowingSummary =
    configRef.current === null
      ? true
      : configRef.current.waitForConnectionBeforeShowingSummary

  if (
    defaultWaitForConnectionBeforeShowingSummary === true &&
    customer != null &&
    customer !== previousCustomer
  ) {
    setIsConnected(false)
    setPreviousCustomer(customer)
  }

  if (customer == null && isConnected === true) {
    log(apiToken, { level: 'warn', message: 'missing customer', data: {} })
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on init
  useEffect(() => {
    let apiToken: string | null = null
    let debugLogging = false
    let waitForConnectionBeforeShowingSummary = true

    const doStuff = async (): Promise<void> => {
      try {
        const queryString = window.location.search
        const params = new URLSearchParams(queryString)

        const api = await connect()
        apiToken = params.get('token')
        if (!apiToken) {
          throw new Error('No token found')
        }
        setApiToken(apiToken)
        const rawLocale = api?._config?.system?.locale
        const locale = extractLocale(rawLocale)

        if (locale) {
          i18n.activate(locale)
        } else {
          log(apiToken, {
            level: 'warn',
            message: 'invalid locale',
            data: {
              raw: rawLocale,
              extracted: extractLocale(locale),
            },
          })
        }

        if (configRef.current == null) {
          const r = await getConfig(apiToken)

          debugLogging = r.debugLogging
          waitForConnectionBeforeShowingSummary =
            r.waitForConnectionBeforeShowingSummary

          configRef.current = r

          if (debugLogging) {
            log(apiToken, {
              level: 'info',
              message: 'initialized',
              data: {
                org: params.get('org'),
                api,
              },
            })
          }
        } else {
          debugLogging = configRef.current.debugLogging
          waitForConnectionBeforeShowingSummary =
            configRef.current.waitForConnectionBeforeShowingSummary
        }

        const organizationUid = params.get('org')
        if (organizationUid) {
          posthog?.identify(organizationUid)
        }

        api.on('SYSTEM_REQUEST_IS_CONNECTED', () => {
          if (debugLogging) {
            log(apiToken, {
              level: 'info',
              message: 'SYSTEM_REQUEST_IS_CONNECTED',
              data: {},
            })
          }
          setIsConnected(true)
        })

        api.on('SYSTEM_INCOMING_CALL', (d: { call: PuzzelCall }): void => {
          const { call } = d || {}
          setCustomer(call?.caller)

          if (waitForConnectionBeforeShowingSummary === false) {
            setIsConnected(true)
          }

          if (debugLogging) {
            log(apiToken, {
              level: 'info',
              message: 'SYSTEM_INCOMING_CALL',
              data: {
                hasCaller: !!call?.caller,
                waitForConnectionBeforeShowingSummary,
              },
            })
          }
        })

        api.on('SYSTEM_CALL_TRIGGER', (vars: SystemCall) => {
          const {
            system_call_progress,
            __CLIENT_IS_OUTBOUND__,
            ciq_destination = '',
          } = vars

          if (debugLogging) {
            log(apiToken, {
              level: 'info',
              message: 'SYSTEM_CALL_TRIGGER',
              data: {
                hasCaller: !!ciq_destination,
                waitForConnectionBeforeShowingSummary,
                system_call_progress,
                __CLIENT_IS_OUTBOUND__,
              },
            })
          }

          if (
            waitForConnectionBeforeShowingSummary &&
            system_call_progress === 'CONNECTED'
          ) {
            setIsConnected(true)
          } else if (__CLIENT_IS_OUTBOUND__ === true) {
            setIsConnected(true)
          } else if (
            waitForConnectionBeforeShowingSummary === false &&
            system_call_progress === 'ALERTING'
          ) {
            setIsConnected(true)
          }

          const customer = ciq_destination
          setCustomer(customer)
          if (!customer) {
            log(apiToken, {
              level: 'warn',
              message: 'SYSTEM_CALL_TRIGGER customer number empty',
              data: {
                hasCaller: !!ciq_destination,
                waitForConnectionBeforeShowingSummary,
                system_call_progress,
                __CLIENT_IS_OUTBOUND__,
              },
            })
          }
        })

        await api.ready()
      } catch (error) {
        const e = await extractHttpError(error)
        log(apiToken, {
          data: { name: e.name, message: e.message, ...e.cause },
          level: 'error',
          message: 'init error',
        })

        if (error instanceof Error) {
          setError(error)
        } else {
          setError(new Error(String(error)))
        }
      }
    }

    doStuff()
  }, [])

  if (error) return <ErrorComponent error={error} />
  if (apiToken == null) return <div />

  if (!isConnected) return <NotConnected />
  if (!customer)
    return <ErrorComponent error={new Error(t`Missing customer number`)} />

  return <Widget apiToken={apiToken} filter={{ customer }} />
}

export default PuzzelLayer
