import { Box } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import ErrorComponent from 'components/ErrorComponent'
import NoFirstConversation from 'components/NoFirstConversation'
import Conversations from 'components/PreviousConversations/Conversations'
import NoConversations from 'components/PreviousConversations/NoConversations'
import VoiceBot from 'components/VoiceBot'
import { log } from 'components/api/log'
import { type Filter, useConversations } from 'components/api/useConversations'
import { useVoiceBot } from 'components/api/useVoiceBot'
import HeaderDivider from 'components/styles/HeaderDivider'
import { usePostHog } from 'posthog-js/react'
import React, { useEffect, useRef } from 'react'

type Props = {
  filter?: Filter
  apiToken: string
}

const Widget: React.FC<Props> = ({ filter, apiToken }) => {
  const posthog = usePostHog()
  const dataSent = useRef<boolean>(false)
  const { data, error } = useConversations(apiToken, filter)
  const voiceBot = useVoiceBot(apiToken, { phoneNumber: filter?.customer })

  useEffect(() => {
    if (data && dataSent.current === false) {
      posthog?.capture('summary_SummaryOnConversations', {
        numberOfSummaries: data.length,
      })

      log(apiToken, {
        level: 'info',
        message: 'showing summaries page',
        data: {
          numberOfSummaries: data.length,
        },
      })

      dataSent.current = true
    }
  }, [apiToken, data, posthog])

  if (error) return <ErrorComponent error={error} />
  if (!data || voiceBot.isLoading) return <NoFirstConversation />

  const voiceBotDetails = voiceBot.data
  return (
    <Box mx="5">
      {voiceBotDetails && (
        <>
          <HeaderDivider>🟢 {t`Current conversation`} 🟢</HeaderDivider>
          <VoiceBot
            date={new Date(voiceBotDetails.date)}
            subTopic={voiceBotDetails.subTopic}
            summary={voiceBotDetails.summary}
            topic={voiceBotDetails.topic}
            error={voiceBot.error}
            isLoading={voiceBotDetails.status === 'Processing'}
          />
        </>
      )}
      {data.length === 0 && <NoConversations />}
      {data.length > 0 && (
        <HeaderDivider>🕰️ {t`Previous conversations`} 🕰️</HeaderDivider>
      )}
      {data.map(([date, conversations]) => (
        <Conversations conversations={conversations} date={date} key={date} />
      ))}
    </Box>
  )
}

export default Widget
